import { AddFavourite } from "@/store/actions/campaign";
import { settingsData } from "@/store/reducer/settingsSlice";
import { store } from "@/store/store";
import {
  formatPriceAbbreviated,
  isThemeEnabled,
  placeholderImage,
  translate,
} from "@/utils";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { useSelector } from "react-redux";
import Image from "next/image";
import { ImageToSvg } from "./ImageToSvg";
import Swal from "sweetalert2";
import LoginModal from "../LoginModal/LoginModal";
import verifiedBadge from "@/assets/verified_badge.png";
import { FaWhatsapp, FaRegMoneyBillAlt } from "react-icons/fa";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { FreeMode, Pagination, Navigation } from "swiper/modules";

import { languageData } from "@/store/reducer/languageSlice";
import { useRouter } from "next/router";
import Link from "next/link";

function VerticalCard({ ele, removeCard, onImageLoad, withButtons }) {
  const router = useRouter();

  const lang = useSelector(languageData);
  useEffect(() => {}, [lang]);
  const language = store.getState().Language.languages;

  const priceSymbol = useSelector(settingsData);
  const CurrencySymbol = priceSymbol && priceSymbol.currency_symbol;

  const swiperRefNavg = useRef(null);

  const isLoggedIn = useSelector((state) => state.User_signup);

  const handleImageLoad = () => {
    if (onImageLoad) {
      onImageLoad();
    }
  };
  // Initialize isLiked based on ele?.is_favourite
  const [isLiked, setIsLiked] = useState(ele?.is_favourite === 1);

  // Initialize isDisliked as false
  const [isDisliked, setIsDisliked] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLike = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (isLoggedIn && isLoggedIn.data && isLoggedIn.data.token) {
      AddFavourite(
        ele?.id,
        "1",
        (response) => {
          setIsLiked(true);
          setIsDisliked(false);
          toast.success(response.message);
        },
        (error) => {
          console.log(error);
        },
      );
    } else {
      Swal.fire({
        title: translate("plzLogFirst"),
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: false,
        showCancelButton: true,
        allowOutsideClick: true,
        customClass: {
          confirmButton: "Swal-confirm-buttons",
          cancelButton: "Swal-cancel-buttons",
        },
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          router.push("/login");
        }
      });
    }
  };

  const handleDislike = (e) => {
    e.preventDefault();
    e.stopPropagation();
    AddFavourite(
      ele?.id,
      "0",
      (response) => {
        setIsLiked(false);
        setIsDisliked(true);
        toast.success(response.message);
        if (removeCard) {
          setIsLiked(true);

          removeCard(ele?.id);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  useEffect(() => {
    // Update the state based on ele?.is_favourite when the component mounts
    setIsLiked(ele?.is_favourite === 1);
    setIsDisliked(false);
  }, [ele?.is_favourite]);

  const DummyImgData = useSelector(settingsData);
  const PlaceHolderImg = DummyImgData?.web_placeholder_logo;
  const themeEnabled = isThemeEnabled();

  return (
    <div className="verticle_card w-[100%]">
      <div className="card verticle_main_card">
        <div className="verticle_card_img_div">
          <Swiper
            ref={swiperRefNavg}
            slidesPerView={1}
            spaceBetween={0}
            freeMode={false}
            allowTouchMove={false}
            simulateTouch={false}
            touchMoveStopPropagation={false}
            pagination={{
              clickable: false,
            }}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation]}
            className="properity-card-gallery"
          >
            {ele?.gallery ? (
              // Show skeleton loading when data is being fetched
              <Swiper
                dir={language.rtl === "1" ? "rtl" : "ltr"}
                slidesPerView={1}
                spaceBetween={1}
                freeMode={false}
                modules={[FreeMode, Pagination]}
                className="properity-card-gallery"
              >
                <SwiperSlide>
                  <div className="loading_data">
                    <Image
                      loading="lazy"
                      className="properity-card-gallery-image"
                      src={ele?.title_image}
                      alt="no_img"
                      width={200}
                      height={200}
                      onLoad={handleImageLoad}
                      onError={placeholderImage}
                    />
                  </div>
                </SwiperSlide>
                {ele?.gallery.slice(0, 4).map((img, index) => (
                  <SwiperSlide key={index}>
                    <div className="loading_data">
                      <Image
                        loading="lazy"
                        className="properity-card-gallery-image"
                        src={img?.image_url ? img?.image_url : PlaceHolderImg}
                        alt="no_img"
                        width={356}
                        height={200}
                        onLoad={handleImageLoad}
                        onError={placeholderImage}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <Image
                loading="lazy"
                className="card-img"
                id="verticle_card_img"
                src={ele?.title_image}
                alt="no_img"
                width={200}
                height={200}
                onLoad={handleImageLoad}
                onError={placeholderImage}
              />
            )}
          </Swiper>
        </div>
        <div className="card-img-overlay">
          {ele?.promoted ? (
            <span className="feature_tag">{translate("feature")}</span>
          ) : null}

          <span className="verified_badge">Single family home</span>
          {/* {ele?.added_by === 0 && (
          )} */}
        </div>

        <div className="card-body">
          <div className="feature_card_mainbody">
            <span className="feature_body_title">
              {ele?.category && ele?.category.category}{" "}
            </span>
          </div>

          <div className="feature_card_middletext">
            <div className="flex justify-between items-center mb-1">
              <span className="price_teg">
                {formatPriceAbbreviated(ele?.price)} {CurrencySymbol}
              </span>
              <div className="justify-self-end col-span-3 flex">
                <span className="rounded-full border p-2 text-black border-black w-[42px] h-[42px]">
                  {isLiked ? (
                    <AiFillHeart
                      size={24}
                      className="liked-property"
                      onClick={handleDislike}
                    />
                  ) : isDisliked ? (
                    <AiOutlineHeart
                      size={24}
                      className="disliked-property"
                      onClick={handleLike}
                    />
                  ) : (
                    <AiOutlineHeart size={24} onClick={handleLike} />
                  )}
                </span>
              </div>
            </div>

            {/* static down payment */}
            <p className="mb-1 h5 text-[#0075FF]">Down Payment 20,000</p>

            <p className="address_teg h4 mb-4 text-slate-700">
              {ele?.city} {ele?.city ? "," : null} {ele?.state}{" "}
              {ele?.state ? "," : null} {ele?.country}
            </p>
          </div>
        </div>

        <div className="card-footer" id="feature_card_footer">
          <div className="d-flex gap-2 flex-wrap">
            {ele?.parameters &&
              ele?.parameters.slice(0, 4).map(
                (elem, index) =>
                  elem?.value !== "" &&
                  elem?.value !== "0" && (
                    <div className="footer_content" key={index}>
                      <div className="footer_img_value">
                        {themeEnabled ? (
                          <ImageToSvg
                            imageUrl={elem?.image}
                            className="custom-svg"
                          />
                        ) : (
                          <Image
                            loading="lazy"
                            src={elem?.image}
                            alt="no_img"
                            width={20}
                            height={16}
                            onError={placeholderImage}
                          />
                        )}
                        <p className="text_footer">
                          {Array.isArray(elem?.value)
                            ? elem.value.slice(0, 2).join(", ")
                            : elem.value}
                          {elem?.name !== "Size" && <span> {elem?.name}</span>}
                        </p>
                      </div>
                    </div>
                  ),
              )}
          </div>

          {withButtons === false ? (
            ""
          ) : (
            <div className="grid grid-cols-3 gap-3 mt-3 mb-3">
              <p className="text-gray-600 text-center pt-2 text-sm md:text-md">
                {ele?.post_created}
              </p>
              <Link
                href={`https://wa.me/${ele.mobile.replace(/\s+/g, "")}`}
                target="_blank"
                className="button shadow-sm shadow-primary rounded-lg px-3 space-x-1"
              >
                <span className="inline-flex text-primary">
                  <FaWhatsapp />
                </span>
                <span className="text-xs md:text-sm">
                  {translate("whatsapp")}
                </span>
              </Link>
              <Link
                href=""
                target="_blank"
                className="button flex-1 shadow-sm shadow-primary rounded-lg px-3 space-x-1"
              >
                <span className="inline-flex text-primary">
                  <FaRegMoneyBillAlt />
                </span>
                <span className="text-xs md:text-sm whitespace-nowrap">
                  {translate("payDeposit")}
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <LoginModal isOpen={showModal} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default VerticalCard;

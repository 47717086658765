import React, { useState } from "react";
import baityLogo from "@/assets/Logo_Color.png";
import { FiMail, FiPhone } from "react-icons/fi";
import {
  AiFillTwitterCircle,
  AiOutlineInstagram,
  AiOutlineLinkedin,
} from "react-icons/ai";
import { CiFacebook } from "react-icons/ci";
import { ImPinterest2 } from "react-icons/im";
import playstore from "../../assets/playStore.png";
import appstore from "../../assets/appStore.png";

import Link from "next/link";
import { useSelector } from "react-redux";
import { settingsData } from "@/store/reducer/settingsSlice";
import { placeholderImage, translate } from "@/utils";

import Image from "next/image";
import {
  FaFacebookF,
  FaSnapchat,
  FaXTwitter,
  FaTiktok,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa6";

const Footer = () => {
  const systemData = useSelector(settingsData);
  const webdata = systemData && systemData;
  const currentYear = new Date().getFullYear();
  const settingData = useSelector(settingsData);

  const [email, setEmail] = useState();
  const handleSubmit = (event) => {
    event.preventDefault();
    // You can integrate with your backend or a service like Mailchimp here
    alert(`Subscription request sent for: ${email}`);
    setEmail(""); // Clear the input after submission
  };

  return (
    <section id="footer" className="md:p-[3rem] container">
      <div className="container">
        <div className="footer-widgets" id="footer_deatils">
          <div className="widget-item" id="widget_logo">
            <div id="footer_logo_section">
              {webdata?.web_footer_logo && (
                <Link href="/">
                  <Image
                    loading="lazy"
                    src={baityLogo}
                    alt="eBroker_logo"
                    width={350}
                    height={157}
                    className="footer_logo"
                    onError={placeholderImage}
                  />
                </Link>
              )}
              {webdata && webdata.company_name && (
                <div className="download_app_desc">
                  <span>
                    {translate("Getthelatest")} {webdata?.company_name}{" "}
                    {translate("Selectyourdevice")}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="widget-item">
            <div id="footer_prop_section">
              <div id="footer_headlines">
                <span>{translate("about")}</span>
              </div>
              <div className="prop_links">
                <Link href="/about-us">{translate("aboutUs")}</Link>
              </div>
              <div className="prop_links">
                <Link href="/how-it-works">{translate("howItWorks")}</Link>
              </div>
              <div className="prop_links">
                <Link href="/terms-of-use">{translate("termsOfUse")}</Link>
              </div>

              <div className="prop_links">
                <Link href="/help">{translate("help")}</Link>
              </div>
            </div>
          </div>

          <div className="widget-item">
            <div id="footer_contact_section">
              <div id="footer_headlines">
                <span>{translate("contact")}</span>
              </div>
              <div className="page_links">
                <Link href="/care">{translate("baityCare")}</Link>
              </div>
              {webdata && webdata.company_tel1 && (
                <div className="page_links">
                  <Link href={`tel:${webdata && webdata.company_tel1}`}>
                    <span className="footer_span_value">
                      {webdata && webdata.company_tel1}
                    </span>
                  </Link>
                </div>
              )}
              {webdata && webdata.company_tel2 && (
                <div className="page_links">
                  <Link href={`tel:${webdata && webdata.company_tel2}`}>
                    <span className="footer_span_value">
                      {webdata && webdata.company_tel2}
                    </span>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="widget-item">
            <div id="footer_page_section">
              <div id="footer_headlines">
                <span>{translate("followOurSocial")}</span>
              </div>
              <div className="page_links grid grid-cols-6 gap-4">
                <Link href="">
                  <FaFacebookF size={28} />
                </Link>
                <Link href="">
                  <FaInstagram size={28} />
                </Link>
                <Link href="">
                  <FaXTwitter size={28} />
                </Link>
                <Link href="">
                  <FaSnapchat size={28} />
                </Link>
                <Link href="">
                  <FaTiktok size={28} />
                </Link>
                <Link href="">
                  <FaYoutube size={28} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="rights_footer">
          <p className="mb-0 order-2 order-md-1">
            {translate("copyright")} {currentYear} {webdata?.company_name}
          </p>
          {webdata?.facebook_id ||
          webdata?.instagram_id ||
          webdata?.pintrest_id ||
          webdata?.twitter_id ? (
            <div id="follow_us" className="order-1 order-md-2">
              {webdata?.facebook_id ? (
                <Link
                  href={webdata?.facebook_id}
                  target="_blank"
                  aria-label="facebook page"
                >
                  <CiFacebook size={28} />
                  <span className="hidden-text-seo">facebook page</span>
                </Link>
              ) : null}
              {webdata?.instagram_id ? (
                <Link
                  href={webdata?.instagram_id}
                  target="_blank"
                  aria-label="instagram page"
                >
                  <AiOutlineInstagram size={28} />
                  <span className="hidden-text-seo">instagram profile</span>
                </Link>
              ) : null}
              {webdata?.pintrest_id ? (
                <Link
                  href={webdata?.pintrest_id}
                  target="_blank"
                  aria-label="pintrest page"
                >
                  <ImPinterest2 size={25} />
                  <span className="hidden-text-seo">pintrest page</span>
                </Link>
              ) : null}
              {webdata?.twitter_id ? (
                <Link
                  href={webdata?.twitter_id}
                  target="_blank"
                  aria-label="twitter page"
                >
                  <FaXTwitter size={25} />
                  <span className="hidden-text-seo">twitter page</span>
                </Link>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default Footer;
